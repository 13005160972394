.savedSimulations {
  display: flex;
  flex-direction: column;
  height: 100%;

  #{&}__list {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0; // this is the most important one if we want to make flex item scrollable

    overflow: scroll;
    height: 100%;
  }

  button {
    &.loadToEndToEnd {
      background: #00825F;
      border-color: #00825F;
    }
  }

  .copyable_link {
    margin-bottom: 0;
  }
}
