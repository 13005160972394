@import 'root';
@import 'aside';
@import 'right_aside_slider';
@import 'content';
@import 'menu';
@import 'header';

// Technically .ant-layout is on the same element with the 'Main'
// NOTE: DO NOT nest anything inside here
// cause .ant-layout could have another .ant-layout inside
.ant-layout {
  background: #fff;
  height: 100%; // smelling some issue later on
}
