@import 'pages/InternationalizeVersions/ViewVersion';
@import 'pages/InternationalizeVersions/CloneVersion';

@import 'pages/FaqVersions/ViewVersion';
@import 'pages/FaqVersions/CloneVersion';

@import 'components/VersionActionsButtons';

.AddNewVersion,
.EditVersion,
.ShowVersion {
  .ant-page-header {
    padding-left: 0;
    padding-right: 0;
  }
}
