.DataStats__Collapsable {
  .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    height: 37px;
  }

  [class*='DataStats_stats'] {
    padding-top: 12px;
    padding-bottom: 40px;
  }
}