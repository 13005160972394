.expandTestReport {
  padding-left: 40px;

  .testPassed {
    color: limegreen;
  }

  .testFailed {
    color: red
  }

  .collapsePanel {
    border: none !important;
  }

  .toMatchText {
    font-weight: bold;
  }
}
