.SentenceQueryResultsTable {
  .Selected {
    background-color: rgba(128, 128, 128, 0.2);
    :global {
      a {
        color: rgba(0,0,0,0.85);
      }
    }
  }
}

.TrainingStatus {
  min-width: 110px;
  text-align: center;

  &.NEW {
    color: #2791E6;
  }

  &.SUBMITTED {
    color: #FBAD1C;
  }

  &.REJECTED {
    color: #F24726;
  }

  &.APPROVED {
    color: #0CA789;
  }

  &.EXCLUDED {
    color: #808080;
  }
}
