.left_sider {
  overflow: visible; // should be ok since antd specify min, max, flex-basis width via js

  .left_sider__collapse_button_container {
    position: absolute;
    right: -16px;
    z-index: 1;

    &.collapsed {
      right: 0;
    }
  }

  .left_sider__query_expanded {
    display: block;
  }

  .left_sider__query_collapsed {
    display: none;
  }

  .left_sider__divider{
    margin: 8px 0 !important;
  }

  .left_sider__search_button {
    margin-top: 10px;
  }
}
