.variations_label {
  margin-bottom: 8px;
  display: block;

  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.btn__Splitline_delete {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: #777;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

