// Elements — styling for bare HTML elements (like H1, A, header, footer, …).
// These come with default styling from the browser so we must to redefine them here.
html, body, main {
  height: auto;
}

body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

mark {
  background-color: yellow;
}

button {
  border: none;
  background: none;
}

// Non-user friendly button
div[role='button'] {
  outline: none;

  &[disabled] {
    cursor: default;
  }
}

.App {
  height: 100%;
  background: #fff;
  text-align: center;
}

@import 'buttons/style';
