.EmailContent {
  .Subject {
    display: block;
    max-width: 500px;
    word-break: break-all;
  }

  .Body {
    white-space: pre-wrap;
    max-width: 500px;

    // Via JS
    &.Expandable:not(.Expanded) {
      overflow: hidden;
      display: -webkit-box;
      display: -moz-box;
      -webkit-box-orient: vertical;
      box-orient: vertical;
      -webkit-line-clamp: 15;
      line-clamp: 15;

      -webkit-box-align: start;
      box-align: start;
    }

    p {
      margin: 0;
    }
  }

  .ExpandContent {
    padding-left: 0;
    padding-right: 0;
  }
}
