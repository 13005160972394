@mixin button-shared-property {
  @include reset-default-styling();
  border: none;
  outline: none;
  background: none;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  min-height: 50px;
  cursor: pointer;
}

@mixin button-disabled() {
  &:disabled,
  &[disabled] {
    background: #f7f7f7;
    color: #727c8a;
  }
}

@mixin set-hover-background-color($color) {
  // Add another query for this :hover next to current one if needed
  &:not([class*='loading']):hover:enabled {
    background: $color;
    border-color: transparent;
  }
}

@mixin set-active-background-color($color) {
  // Add another query for this :active next to current one if needed
  &:not([class*='loading']):active:enabled {
    background: $color;
    border-color: transparent;
  }
}

@import 'primary';
@import 'secondary';
@import 'tertiary';
