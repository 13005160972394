.Conversations_Tags {
  overflow: scroll;

  .ant-page-header,
  .ant-page-header.ant-page-header-ghost {
    padding: 0;
    .ant-page-header-heading-title .ant-typography {
      margin: 0;
    }
  }
}
