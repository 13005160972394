// sorted as left -> right

// Background Grey Colors
// ------
$grey-primary: #727c8a !default;
$grey-secondary: #c1cad1 !default;
$grey-tertiary: #dce0ea !default;
$light-grey-primary: #edf1fc !default;
$light-grey-secondary: #f7f7f7 !default;
$light-grey-tertiary: #f8f9f9 !default;
$white: #ffffff !default;
$black: #000000 !default;

// Accent colors
// ------
$ocean: #0d81c9 !default;
$lake: #009aef !default;
$river: #0ac0dd !default;
$grass: #2ed180 !default;
$slate: #3d536c !default;
$dark: #1b2d40 !default;

// Signal Colors
// ------
$chime-ok: #2ed180 !default;
$chime-warning: #f5a623 !default;
$chime-error: #e63e52 !default;

// Gradients
// ------
$blue-gradient: linear-gradient(-135deg, rgb(13, 129, 201) 0%, rgb(10, 192, 221) 100%) !default;
$green-gradient: linear-gradient(-135deg, rgb(46, 209, 128) 0%, rgb(10, 192, 221) 100%) !default;

// Border colors
// ------
$border-lake: #009aef !default;
$border-river: #0ac0dd !default;
$border-grass: #2ed180 !default;
$border-slate: #3d536c !default;
$border-grey-primary: #727c8a !default;
$border-grey-secondary: #c1cad1 !default;
$border-grey-tertiary: #dce0ea !default;
$border-light-grey-primary: #edf1fc !default;

// Inputs
// ------
// #dce0ea !default;
// #727c8a !default;
// #fdfafa !default;
