[class*="SelectedSentence_Top__header"] {
  padding-left: 0;
  padding-right: 0;
  border: 0;
}

.messages__sentence_navigator {
  div:first-child {
    text-align: left;
  }

  div:last-child {
    text-align: right;
  }
}
