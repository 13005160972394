.metadata,
.metadata__ListHeader,
.metadata__ListFooter {
  text-align: left;
}

.metadata__List {
  max-height: 50vh;
  overflow-y: auto;
}

.metadata__ListFooter {
  text-align: center;
  font-weight: bold;
}

.metadata__ListBody {
  max-height: 30vh;
  overflow-y: auto;
}
