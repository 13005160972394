.PreprocessedSentence {
  margin-bottom: 12px;

  .Symbol {
    color: #000000;
    &:hover {
      color: #000000;
      background-color: #ffe58f;
    }
  }
}
