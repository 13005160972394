.ant-list-item {
  .ant-list-item-action {
    a {
      font-weight: bold;
    }
  }
}

.ant-modal-confirm-content {
  ul {
    padding-left: 0;
    margin-left: 16px;
  }
}

.ant-list {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper .ant-checkbox-inner,
  .ant-checkbox .ant-checkbox-inner,
  .ant-checkbox-input + .ant-checkbox-inner {
    border-width: 2px;
  }

  .ant-list-footer {
    border: 1px solid #f0f0f0;
  }
}

.statistic_container {
  display: flex;
  flex-wrap: wrap;
}

.ant-descriptions {
  .ant-descriptions-row {
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      padding: 8px;
    }

    .ant-descriptions-item-label {
      padding-left: 12px;
      max-width: 240px;
    }

    .ant-descriptions-item-content {
      min-width: 100px;
    }
  }
}

[class*='ConversationListItem_conversation_list_item'] {
  .ant-space {
    width: 100%;
  }
}

.ant-menu-submenu-popup{
  &::before {
    top: 0;
  }
}

.react-flow{
  .react-flow__attribution{
    visibility: hidden;
  }
}
