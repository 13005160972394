.SimulatedConversationStats {
  height: 100%;
  position: relative;
}

.title {
  display: block;
  position: absolute;
  background-color: #ECF6FF;
  color: #006FCF;
  top: 0;
  left: 0;
  right: 0;
  margin-right: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 8px;

  font-size: 14px;
  font-weight: bold;

  z-index: 1;
}

%defaultStats {
  // height: 100%; // css-modules is useless here, antd overrides with more specifity 10, 1
  resize: none;
  font-family: monospace;
  white-space: pre;
  overflow-x: auto;

  padding-top: 40px;
  padding-bottom: 32px;
}

.stats {
  @extend %defaultStats;
}

.stats_error {
  @extend %defaultStats;
  border-color: red;
}

.synchronize_stats {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: #00825F;
  border-color: transparent;
  color: #ffffff;

  font-weight: 600;
  &:hover {
    background-color: #ffffff;
    border-color: #00825F;
    color: #00825F;
  }
}
