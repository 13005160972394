// NOTE: Originally .conversation-list__list
[class*="conversation-list__list"] {
  .ant-list-items {
    max-height: 70vh;
    overflow-y: auto;
  }

  .ant-list-item-meta {
    align-self: flex-start;
    flex: initial;
  }
}
