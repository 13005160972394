[class*="Main__content"] {
  // FIXME: This has to be removed because it affects inside content too
  // it's too global... and this has been made for 18 months ago...
  .ant-divider + .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-layout-content {
    padding: 24px;
    z-index: 0; // Fix to have Utterances Analysis stays on top
  }

  // Needs a hack because antd insert this class dynamically
  &.ant-layout-has-sider {
    & > [class*="VersionLabel_Badge"] {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    & > .ant-layout-sider.aside_filter,
    & > .ant-layout-content {
      margin-top: 30px;
    }
  }
}
