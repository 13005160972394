.itemDescriptionStyle {
  padding: 0
}

.popoverContentStyle {
  width: '80vw';
  height: 'auto';
  overflow: 'auto';
  word-break: 'normal';
  word-wrap: 'normal';
}
