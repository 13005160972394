.SearchResults {
  padding: 0 24px 24px;
  flex: 1;
  overflow-x: auto;

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .ResultOperationActions {
      display: flex;
      align-items: center;
    }
  }

  .AlignTop {
    // or use this in top level
    /*
      table {
        tbody {
          tr > td:nth-child(n+3) {
            vertical-align: top;
          }
        }
      }
    */
    vertical-align: top;
  }
}

td {
  vertical-align: baseline;
}
