@use "sass:color";

@mixin generateButtonColorSet($color, $disabled: false) {
  color: $color;
  background: color.change($color, $alpha: 0.1);
  border-color: $color;

  &:hover {
    @if $disabled {
      color: $color;
      border-color: $color;
    } else {
      color: color.change($color, $saturation: 100%);
      border-color: color.change($color, $saturation: 100%);
    }
  }
}

.IntentTrainingApprovalStatus {
  &.SUBMITTED {
    color: #FBAD1C;
  }
  &.APPROVED {
    color: #8FD14F;
  }
  &.REJECTED {
    color: #F24726;
  }
  &.UNAVAILABLE {
    color: #2150A7;
  }
}

.IntentTrainingCaseTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  :global {
    h5.ant-typography {
      margin: 0;
    }
  }

  .ApproveAll {
    @include generateButtonColorSet(#6DAE2D);
    &:disabled {
      @include generateButtonColorSet(#D6D6D6, $disabled: true);
    }
  }

  .RejectAll {
    @include generateButtonColorSet(#F24726);
    &:disabled {
      @include generateButtonColorSet(#D6D6D6, $disabled: true);
    }
  }
}

.SubmissionIcons {
  font-size: 20px;
}

:global {
  tr.ant-table-row:local(.LevelTwoIntent) {
    td {
      background: #F5F5F5;
    }

    &:hover {
      & > td {
        background: #E5E5E5;
      }
    }
  }

  tr.ant-table-row:local(.RowIsSelected) {
    td:first-child {
      border-left: 3px solid #006FCF;
    }
  }

  tr.ant-table-row:local(.PositiveSelected):local(.RowIsSubmitted),
  tr.ant-table-row:local(.NegativeSelected):local(.RowIsSubmitted) {
    td:nth-child(2),
    td:nth-child(3) {
      span.ant-typography {
        cursor: not-allowed !important; // fuck this shit
      }
    }
  }

  tr.ant-table-row:local(.PositiveSelected):local(.RowIsSubmitted) {
    td:nth-child(3) {
      span.ant-typography {
        color: #00000040;
      }
    }
  }

  tr.ant-table-row:local(.NegativeSelected):local(.RowIsSubmitted) {
    td:nth-child(2) {
      span.ant-typography {
        color: #00000040;
      }
    }
  }
}
