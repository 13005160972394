.conversation_container {
  .ant-list-items {
    display: flex;
    flex-direction: column;
  }

  &.only_customer_message {
    div[class*='ConversationPage_message_container']:not([class*='ConversationPage_customer']){
      display: none;
    }
  }
}
