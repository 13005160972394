.sentence__query-result {
  .ant-list-footer {
    .ant-space-item {
      margin-top: 8px;
    }
  }

  .ant-list-items {
    max-height: 69vh;
    overflow-y: auto;
  }
}

.SentenceConvertsationInspectorDrawer {
  .ant-drawer-header {
    padding: 16px;
  }
}
