.AlertFeedback {
  margin-top: 8px;
  margin-bottom: 8px;

  :global {
    ul {
      margin-bottom: 0;
    }
  }
}

.Title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
