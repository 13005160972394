.ConversationRightSider {
  height: calc(100vh - 96px);
  min-height: 864px;
  top: 30px;
  overflow: hidden;


  :global {
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
    }

    .conversation_container {
      overflow-y: auto;
      overflow-x: hidden;
      flex: 1;
    }
  }
}
