.variations_label {
  margin-bottom: 8px;
  display: block;

  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.degradedMessages {
  margin-bottom: 24px;
}
