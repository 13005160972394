.IntentEvaluation {
  .IntentLabel,
  .IntentName {
    font-size: 18px;
  }

  .AccuracyLabel,
  .AccuracyValue {
    font-size: 16px;
  }

  .IntentName,
  .AccuracyValue {
    font-weight: 600;
  }
}
