.ExchangeMessages {
  .ExchangeDetails {
    text-align: center;
    margin-top: 16px;
    padding-bottom: 16px;
  }
}

.Save {
  background: #00825F;
  border-color: #00825F;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;

  &:hover,
  &:active,
  &:focus {
    border-color: #E7E8EE;
    color: #00825F;
  }
}

.MatchingCondition {
  width: 100%;

  .MatchingCondition__DomainIntent {
    margin-bottom: 0;
  }
}

.exchange_label {
  border-radius: 34px;
  padding: 0 8px;
  background: #EAEFFF;
  margin-right: 8px;

  display: inline-block;
}

.exchange_label_text {
  color: #00175A;
}
