.AnalyzeEmailResponseModal {
  top: 20px;
  bottom: 20px;

  .Form {
    .Submit {
      text-align: right;
      margin: 0;
    }
  }

  :global {
    .ant-divider {
      margin: 18px 0;
    }
    // NOTE: This is temporary solution for not blocking comparison analyzer response
    :local(.Form) + .ant-space {
      margin-bottom: 16px;
    }
  }
}
