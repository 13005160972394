.success {
  color: #52c41a;
}

.selected {
  color: #40a9ff;
}

.pending {
  color: #fadb14;
}

.disabled {
  color: #bfbfbf;
}

.spaceX {
  width: 70px;
}

.spaceLineX {
  height: 3px;
  width: 60px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #f5f5f5;
}

.pt16 {
  padding-top: 16px;
}

.pt16 > button:not(:last-child) {
  margin-right: 12px
}

.pt16 > span:not(:last-child) {
  margin-right: 12px
}

.border {
  border: 1px solid #d9d9d9;
  height: 100%;
  width: 100%;
  padding: 16px;
}
