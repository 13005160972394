@mixin primary-button-grass-green() {
  @include button-shared-property();
  @include button-disabled();
  @include set-hover-background-color(#25ab69);
  @include set-active-background-color(#18e781);
  @include transition(all, 150ms, ease);
  color: #ffffff;
  background: #2ed180;
}

@mixin primary-button-lake-blue() {
  @include button-shared-property();
  @include button-disabled();
  @include set-hover-background-color(#0d81c9);
  @include set-active-background-color(#26b2ff);
  @include transition(all, 150ms, ease);
  color: #ffffff;
  background: #009aef;
}

@mixin primary-button-red() {
  @include button-shared-property();
  @include button-disabled();
  @include set-hover-background-color(#dc001b);
  @include set-active-background-color($chime-error);
  @include transition(all, 150ms, ease);
  color: #ffffff;
  background: $chime-error;
}

.button-primary-green {
  @include primary-button-grass-green();
}

.button-primary-blue {
  @include primary-button-lake-blue();
}

.button-primary-red {
  @include primary-button-red();
}
