.left_sider__divider{
  margin: 8px 0 !important;
}

.left_sider__collapse_button_container {
  position: absolute;
  right: 0;
}

.left_sider__search_button {
   margin-top: 10px;
}

