.ConversationResponseText {
  overflow: scroll;

  .ant-page-header,
  .ant-page-header.ant-page-header-ghost {
    padding: 0;
    .ant-page-header-heading-title .ant-typography {
      margin: 0;
    }
  }

  .ant-table {
    ul {
      margin: 0;
      white-space: normal;
    }

    .ant-table-tbody > tr > td {
      border-bottom-width: 5px;
    }

    .actions {
      .ant-space-item  {
        button.ant-btn[type='button'] {
          padding-left: 0;
        }
      }
    }

    .ant-divider.ant-divider-horizontal {
      margin: 1.2em;
      border-top-color: #C6C8D2;
    }

    &.ant-table-small .ant-table-footer,
    &.ant-table-small .ant-table-tbody>tr>td,
    &.ant-table-small .ant-table-thead>tr>th,
    &.ant-table-small .ant-table-title,
    &.ant-table-small tfoot>tr>td,
    &.ant-table-small tfoot>tr>th {
      padding: 1.2em 8px;
    }
  }
}
