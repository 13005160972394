.active-action {
  color: #006FCF;
  font-size: 18px;
}

.inactive-action {
  color: #ECF6FF;
  font-size: 18px;
}

.grid2Col {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 24px;
}

.noPaddingContentBox {
  :global {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0px;
    }
  }
}

.sort-loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-next-button {
  color: #8c8c8c;
  cursor: not-allowed;
}

.pagination-next-button-enable {
  color: var(--antd-wave-shadow-color);
  cursor: pointer;
}
