// opacity
.opacity-element {
  &-appear {
    opacity: 0.01;
  }

  &-appear#{&}-appear-active {
    @include transition(opacity, 500ms, ease);

    opacity: 1;
  }

  &-enter {
    opacity: 0.01;
  }

  &-enter#{&}-enter-active {
    @include transition(opacity, 500ms, ease);

    opacity: 1;
  }

  &-leave {
    opacity: 1;
  }

  &-leave#{&}-leave-active {
    @include transition(opacity, 500ms, ease);

    opacity: 0.01;
  }
}

.scale-element {
  &-appear {
    transform: scale(0.01);
  }

  &-appear#{&}-appear-active {
    @include transition(transform, 100ms, ease-in);

    transform: scale(1);
  }

  &-enter {
    transform: scale(0.01);
  }

  &-enter#{&}-enter-active {
    @include transition(transform, 150ms, ease-in);

    transform: scale(1);
  }

  &-leave {
    transform: scale(1);
  }

  &-leave#{&}-leave-active {
    @include transition(transform, 300ms, ease);

    transform: scale(0.01);
  }
}

// chat transition
.chat-transition {
  &-appear {
    opacity: 0.01;
  }

  &-appear#{&}-appear-active {
    @include transition(opacity, 800ms, ease);

    opacity: 1;
  }

  &-enter {
    opacity: 0.01;
  }

  &-enter#{&}-enter-active {
    @include transition(opacity, 800ms, ease);

    opacity: 1;
  }

  &-leave {
    opacity: 1;
  }

  &-leave#{&}-leave-active {
    @include transition(opacity, 800ms, ease);

    opacity: 0.01;
  }
}

// positioning
.move-left-right-element {
  &-appear {
    right: calc((100vw / 3) * -1);
    opacity: 0.01;
  }

  &-appear#{&}-appear-active {
    @include transition(all, 300ms, ease);

    right: 0;
    opacity: 1;
  }

  &-enter {
    right: calc((100vw / 3) * -1);
  }

  &-enter#{&}-enter-active {
    @include transition(right, 300ms, ease);

    right: 0;
  }

  &-leave {
    right: 0;
  }

  &-leave#{&}-leave-active {
    @include transition(right, 300ms, ease);

    right: calc((100vw / 3) * -1);
  }
}

.dynamic-height-opacity-element {
  &-appear {
    opacity: 0.01;
    max-height: 0;
  }

  &-appear#{&}-appear-active {
    @include transition(all, 5s, ease);

    opacity: 1;
    max-height: 500px;
  }

  &-enter {
    opacity: 0.01;
    max-height: 0;
  }

  &-enter#{&}-enter-active {
    @include transition(all, 300ms, ease);

    opacity: 1;
    max-height: 500px;
  }

  &-leave {
    opacity: 1;
    max-height: 500px;
  }

  &-leave#{&}-leave-active {
    @include transition(all, 500ms, ease);

    opacity: 0.01;
    max-height: 0;
  }
}

// input with label
.input-with-label-element {
  &-appear {
    opacity: 0.01;
  }

  &-appear#{&}-appear-active {
    @include transition(opacity, 500ms, ease);

    opacity: 1;
  }

  &-enter {
    opacity: 0.01;
  }

  &-enter#{&}-enter-active {
    @include transition(opacity, 300ms, ease);

    opacity: 1;
  }

  &-leave {
    opacity: 1;
  }

  &-leave#{&}-leave-active {
    @include transition(opacity, 300ms, ease);

    opacity: 0.01;
  }
}
