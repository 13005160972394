.Title {
  margin-bottom: 12px;

  :global {
    .ant-row {
      margin-bottom: 12px;
    }
  }

  .DisplayOptions {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
  }

  .GeneralizedFilter {
    &.Empty {
      display: none;
    }
  }
}

