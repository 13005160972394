.conversation__header {
  padding: 0 18px;
}

.conversation__container {
  max-height: calc(100vh - 172px);
  overflow: scroll;
  padding: 10px;
}

.conversations__header {
  padding: 0 10px;
}

.conversations__container {
  max-height: calc(100vh - 168px);
  overflow: scroll;
  padding: 10px;
}

.conversations__list_item_title:focus {
  color: #1890ff;
}
