@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes frozen {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes header-icon-tip-pulse {
  0% {
    box-shadow: 0 0 0 0px $lake;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}
