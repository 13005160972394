@use "sass:color";

@mixin generateButtonColorSet($color, $disabled: false) {
  color: $color;
  background: color.change($color, $alpha: 0.1);
  border-color: $color;

  &:hover {
    @if $disabled {
      color: $color;
      border-color: $color;
    } else {
      color: color.change($color, $saturation: 100%);
      border-color: color.change($color, $saturation: 100%);
    }
  }
}

.AlertFeedback {
  margin-top: 8px;
  margin-bottom: 8px;
}

.Form {
  margin-top: 16px;
}

.IntentTrainingCaseTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  :global {
    h5.ant-typography {
      margin: 0;
    }
  }

  .ApproveAll {
    @include generateButtonColorSet(#F24726);
    &:disabled {
      @include generateButtonColorSet(#D6D6D6, $disabled: true);
    }
  }

  .RejectAll {
    @include generateButtonColorSet(#6DAE2D);
    &:disabled {
      @include generateButtonColorSet(#D6D6D6, $disabled: true);
    }
  }
}
