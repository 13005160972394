.DataExtractionTable {
  .textCount {
    color: #3CB371;
  }

  :global {
    .ant-table-thead .ant-table-cell {
      background-color: #D8D8D8;
    }

    .ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
      cursor: default;
      margin: -8px -8px 24px 14px;

      .ant-table-tbody>tr>td {
        cursor: default;
      }
    }

    .ant-table.ant-table-middle .ant-table-tbody>tr>td {
      cursor: pointer;
    }
  }
}
