.EmailQueryParams {
  width: 250px;

  .QueryButtonCommands {
    justify-content: space-between;
    width: 100%;
  }

  &.collapsed {
    width: 24px;
    transform: translateY(-24px) rotate(90deg);
    transform-origin: bottom left;
    height: 24px;

    :global {
      form {
        display: none;
      }
    }
  }

  :global {
    .ant-form {
      // whatever followed by .ant-divider same level
      // this would be started matched at the 'by id' divider
      .ant-divider ~ .ant-form-item {
        margin-bottom: 12px;
      }
    }
  }
}

:global .ant-select-selection-placeholder {
  color: #d9d9d9;
}
