.extraButton {
  margin-right: 8px;
}

.testCaseSelectedCount {
  margin-left: 8px;
  font-size: 12px;
  color: gray;
}

.E2EModalTitleDescription {
  margin-left: 8px;
  color: #727c8a
}

.E2ESelectedDeleteModalIcon {
  font-size: 20px;
  margin-right: 16px
}

.E2ENotiSuccessCollectionName {
  font-weight: bold;
}
