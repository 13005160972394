.Pending {
  text-align: center;
  display: flex;
  justify-content: center;
}

.EmptyResult {
  width: 100%;
}

.EmailAnalyzeButton {
  margin-left: auto;
  display: flex;
}
