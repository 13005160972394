.EmailContentPreview {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  z-index: 0;

  .Header {
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 1;
  }

  .EmailContent {
    white-space: pre-wrap;
  }

  .Copy {
    color: #006fcf;
  }

  .Copied {
    color: #52c41a;
    transition: color 0.3s;
    cursor: pointer;
  }
}
