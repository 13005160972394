.EditFaqModal {
  .ant-form {
    .ant-row.ant-form-item.FAQ_Sentence {
      margin-bottom: 4px;
    }
  }

  [class*='EditFaqModal_Errors'] {
    & > [class*='EditFaqModal_Error__response_with_content'] {
      .anticon.ant-alert-icon {
        align-self: flex-start;
        line-height: 24px;
      }
    }
  }
}
