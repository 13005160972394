ul.pagination-has-next-page.ant-pagination.mini  {
   li:last-child {
    position: relative;
    margin-left: 25px;
    min-width: 0;
    width: 0;
  }

  li:last-child:before {
    content: "...";
    display: block;
    position: absolute;
    right: 100%;
    width: 24px;
    border: 1px solid transparent;
    margin-right: 1px;
    pointer-events: none;
  }
}

ul.ant-pagination.mini  {
  li:last-child {
   position: relative;
   min-width: 0;
   width: 0;
 }
}

.ant-table-cell {
  background-color: white;
}
