.ChatExchanges {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.redirectToLoadSimulation {
  appearance: none;
  color: #1890ff;
  outline: none;
  cursor: pointer;
  transition: color .3s;
  padding: 0;
  text-decoration: none;
}

.EndToEnd {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  & > .ant-row {
    height: 100%;
    & > .ant-col:first-child {
      height: 100%;
    }
  }
}
