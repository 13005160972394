$white: #fff;
$dark: #000;

$customer-color: #eceff1;
$agent-color: #40a9ff;
$bot-color: #0aa679;
$system-color: #f85d22;

$pending: #808080;
$sent: #000000;
$error: #f24726;

.date_container {
  text-align: center;
  padding: 5px;
  margin-bottom: 10px;

  span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.message_container {
  font-size: 14px;
  position: relative;
  margin: 0 10px 10px 10px;
  padding: 10px;
  vertical-align: top;
  border-radius: 5px;

  &:before {
    position: absolute;
    top: 19px;
    display: block;
    width: 8px;
    height: 6px;
    content: "\00a0";
    transform: rotate(29deg) skew(-35deg);
  }

  &.customer,
  &.agent,
  &.bot,
  &.system {
    max-width: 80%;
  }

  &.customer {
    color: $dark;
    background-color: $customer-color;
    align-self: flex-start;
    padding-bottom: 4px;

    &:before {
      left: -3px;
      background-color: $customer-color;
    }

    .message_analyze__divider {
      margin: 4px 0;
    }

    .message_analyze__button,
    .message_analyze__domain_intent {
      font-size: 12px;
    }

    .message_analyze__button {
      padding: 0;
      height: auto;
    }
  }

  &.agent {
    color: $white;
    background-color: $agent-color;
    align-self: flex-end;

    &:before {
      right: -3px;
      background-color: $agent-color;
    }
  }

  &.bot {
    color: $white;
    background-color: $bot-color;
    align-self: flex-end;

    &:before {
      right: -3px;
      background-color: $bot-color;
    }
  }

  &.system {
    color: $white;
    background-color: $system-color;
    align-self: flex-end;

    &:before {
      right: -3px;
      background-color: $system-color;
    }
  }
}

.status_container {
  font-size: 12px;
  position: relative;
  vertical-align: top;
  right: -3px;

  &.pending {
    color: $pending;
  }

  &.sent {
    color: $sent;
  }

  &.error {
    color: $error;
  }

  &.left {
    align-self: flex-start;
    margin: 0 0 10px 10px;
  }

  &.right {
    align-self: flex-end;
    margin: 0 20px 10px 0;
  }
}

.message__duration_from_last_message {
  opacity: 0.6;
  margin-left: 5px;
}
