.ConversationDebug {
  overflow: hidden;

  @at-root [class*="Main__content"] .ant-layout-content#{&} {
    padding-bottom: 0;
  }

  .ant-page-header,
  .ant-page-header.ant-page-header-ghost {
    padding: 0;
    .ant-page-header-heading-title .ant-typography {
      margin: 0;
    }
  }

  &__content {
    position: relative;
    height: calc(100% - 66px);
    margin-top: 24px;
    & > .ant-row {
      position: relative;
      overflow: hidden;
      height: 100%;
    }

    .ant-col {
      height: 100%;
    }


    // analyzed_customer_messages
    .ant-col:nth-child(1) .ant-tabs,
    .ant-col:nth-child(2) .ant-list.conversation_container,
    .ant-col:nth-child(3) .analyzed_customer_messages {
      overflow: auto;
      height: calc(100% - 24px);
    }

    .ant-col:nth-child(3) .analyzed_customer_messages {
      height: calc(100% - (24px + 49px));
    }
  }

  .conversation_message_analyzed {
    border-radius: 3px;
    box-shadow: 0 0.0625rem 0.25rem 0.0625rem rgba(26, 35, 62, 0.15);
    transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);

    &:after {
      transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
    }

    &:hover {
      box-shadow: 0 0.25rem 0.5rem 0.0625rem rgba(26, 35, 62, 0.3);
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &:target {
      background: #ECF6FF;
      &:after {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        border: 0.0625rem solid rgba(0, 111, 207, 1);
        border-radius: 4px;
      }
    }
  }
}
