@mixin set-background-and-border-color($background-color, $border-color: $background-color) {
  background-color: $background-color;
  border-color: $border-color;
}

@mixin set-active-color-lighten($color, $intensity) {
  $color-lighten: lighten($color, $intensity);

  &:active {
    @include set-background-and-border-color($color-lighten);
  }
}

@mixin set-hover-color-lighten($color, $intensity) {
  $color-lighten: lighten($color, $intensity);

  &:hover {
    @include set-background-and-border-color($color-lighten);
  }
}

.button__Test {
  @include set-background-and-border-color(#F85D22, #F85D22);
  @include set-active-color-lighten(#F85D22, 5%);
  @include set-hover-color-lighten(#F85D22, 5%);
}

.button__CreateNew {
  @include set-background-and-border-color(#00825F, #00825F);
  @include set-active-color-lighten(#00825F, 5%);
  @include set-hover-color-lighten(#00825F, 5%);
}

.button__ExportToCsv {
  @include set-background-and-border-color(#00175A, #00175A);
  @include set-active-color-lighten(#00175A, 5%);
  @include set-hover-color-lighten(#00175A, 5%);
}
