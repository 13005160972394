.Main {
  height: 100%;
}

.Main__content {
  background: #fff;
  position: relative;
  padding: 0;
  text-align: left;
  height: 100%;

  margin-top: 24px;
}
