.simulation_chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__header {
    padding: 4px 0;
  }

  &__messages {
    // for some reason, flex shorthand does not includes flex-basis
    // so even set the flex: 1 1 0, would make the flex basis behave like 'auto'
    // chrome will totally remove 0 and make the whole thing break even the px unit is specified

    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0; // this is the most important one if we want to make flex item scrollable
    overflow-y: scroll;
    padding: 12px 0;
    margin-bottom: 10px;

    display: flex;
    flex-direction: column;
  }

  &__footer {
    display: flex;

    .message_input {
      flex: 1;
      flex-wrap: nowrap;
      position: relative;
    }

    .message_input_error {
      border-color: red;
    }
    .message_input_error_label {
      position: absolute;
      top: -22px;
      font-size: 12px;
    }
  }

}
