.MatchingCondition {
  width: 100%;

  .MatchingCondition__DomainIntent {
    margin-bottom: 0;
  }
}

.TestCaseId {
  margin-bottom: 12px;
}

.Save {
  background: #00825F;
  border-color: #00825F;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;

  &:hover,
  &:active,
  &:focus {
    border-color: #E7E8EE;
    color: #00825F;
  }
}
