.right_panel__dynamic_entities{
  padding: 25px;
  height: auto;
  max-height: 750px;
  overflow-y: auto;
}

.right_panel__dynamic_entities_content{
  height: auto;
  max-height: 450px;
  overflow-y: auto;
}

.right_panel__dynamic_entities_card{
  border: gray 2px solid;
}

.right_panel__dynamic_entities_add{
  margin-top: 20px;
  width: 100%;
}