.Sentence {
  tbody {
    tr > td {
      vertical-align: top;
      text-align: center;
      
      &.AlignLeft {
        text-align: left;
      }
    }
  }
}
