.AnalysisAccuracy {
  height: 100%;

  .EmailPreviewAndAccuracy {
    height: inherit;

    .OuterEmailPreviewContainer,
    .ResponseLogAndAccuracyCheck {
      height: inherit;
      overflow-y: auto;
    }
  }

  .LastEdited {
    text-align: right;
  }
}
