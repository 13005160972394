.IdenticalResultAlert {
  margin-bottom: 16px;
}

.NlpRulesBasedCollapsible {
  .PreprocessedResponse {
    margin: 0;
    padding-left: 24px;
  }
}
