$white: #fff;
$dark: #000;

$user-color: #eceff1;
$agent-color: #40a9ff;
$bot-color: #0aa679;

// TODO: Fix class later because end_to_end has recursive div
.chat_exchange,
.chat_exchange_end_to_end {
  display: flex;
}

.chat_exchange {
  &:hover {
    background-color: #ECF6FF;
  }

  &.end_to_end_selected,
  &.end_to_end_selected:hover {
    background-color: #EAEFFF;
  }
}

.chat_exchange_end_to_end {
  cursor: pointer;
  position: relative;
  z-index: 0;

  .exchange_badge {
    z-index: 1;
    position: absolute;
    top: 0;
  }

  .exchange_label,
  .exchange_label_selected {
    border-radius: 34px;
    padding: 0 8px;
    font-size: 10px;
    float: left;
  }

  .exchange_label {
    background: #EAEFFF;
    left: 8px;
    margin-right: 8px;
  }

  .exchange_label_selected {
    background: #FFEFE9;
  }

  .exchange_label_text {
    color: #00175A;
  }

  .exchange_label_selected_text {
    color: #C43D0A;
  }
}

.chat_messages {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 8px;
}

.chat_message {
  &__user,
  &__bot {
    max-width: 80%;
    font-size: 14px;
    position: relative;
    margin: 0 10px 10px 10px;
    padding: 10px;
    vertical-align: top;
    border-radius: 5px;

    &:before {
      position: absolute;
      top: 19px;
      display: block;
      width: 8px;
      height: 6px;
      content: "\00a0";
      transform: rotate(29deg) skew(-35deg);
    }
  }

  &__user {
    color: $dark;
    background-color: $user-color;
    align-self: flex-end;

    &:before {
      right: -3px;
      background-color: $user-color;
    }
  }

  &__bot {
    color: $white;
    background-color: $bot-color;
    align-self: flex-start;

    &:before {
      left: -3px;
      background-color: $bot-color;
    }
  }
}
