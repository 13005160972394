.row {
  width: 100%;
}

.col {
  text-align: right;
}

// literally, this does not work
// even they 'append' a random classname no make it unique
// if the library has css specifity higher, it's useless
.confirm_delete {
  color: red;
}
