.RunningTest {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.RunTest {
  background: #00825F;
  border-color: #00825F;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;

  margin-bottom: 16px;

  &:hover,
  &:active,
  &:focus {
    border-color: #E7E8EE;
    color: #00825F;
  }
}

.ExchangeTestCaseList {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  overflow: scroll;
}
