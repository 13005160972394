.FeedbackAndCorrectness {
  text-align: right;
  margin-bottom: 4px;

  display: flex;
  gap: 16px;
  justify-content: space-between;

  .FeedbackAndAccuracy {
    .FeedbackPositive {
      color: green;
    }

    .FeedbackNegative {
      color: orangered;
    }

    .FeedbackEmpty {
      color: gray;
    }

    .AccuracyCorrect {
      color: green;
    }

    .AccuracyInCorrect {
      color: red;
    }

    .AccuracyEmpty {
      color: gold;
    }
  }

  :global {
    .ant-tag:last-of-type {
      margin-right: 0;
    }
  }
}
