.Feedback {
  :global {
    .ant-radio-button-wrapper {
      &.ant-radio-button-wrapper-checked {
        background-color: white;

        &.positive {
          color: green;

          &:hover {
            background-color: unset;
          }
        }

        &.negative {
          color: orangered;

          &:hover {
            background-color: unset;
          }
        }
      }
    }
  }
}
