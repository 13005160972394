// Generic — reset and/or normalize styles, box-sizing definition, etc.
// It is important to note that this is the first layer of the triangle
// that generates CSS.
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Open Sans', sans-serif;
}
