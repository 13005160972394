$positive-background: #b7eb8f;
$negative-background: #ffccc7;

.PredictionHighlights {
  border: 2px dashed yellow;
  padding: 8px;

  &.Positive {
    border-color: $positive-background;
  }

  &.Negative {
    border-color: $negative-background;
  }
}

.PredictionBadge,
.Intent {
  cursor: pointer;
}

.Intent {
  display: inline-block;
  padding: 2px 4px;

  &.Positive.Selected {
    background: $positive-background;
  }

  &.Negative.Selected {
    background: $negative-background;
  }
}
