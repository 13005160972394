#root {
  height: 100%;

  .App > div.ant-spin-nested-loading {
    height: 100%;

    & > .ant-spin-container {
      height: 100%;
    }
  }
}
