.InternationalizeVersion__Clone {
  .ant-page-header,
  .ant-page-header.ant-page-header-ghost {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    .ant-page-header-heading-title .ant-typography {
      margin: 0;
    }
  }
}
