.list_item {
  $white: #fff;
  $dark: #000;

  $customer-color: #eceff1;
  $agent-color: #40a9ff;
  $bot-color: #0aa679;

  .avatar {
    &.customer {
      color: $dark;
      background-color: $customer-color;
    }

    &.agent {
      color: $white;
      background-color: $agent-color;
    }

    &.bot {
      color: $white;
      background-color: $bot-color;
    }
  }
  &.selected {
    background: #ECF6FF;
  }

  .message__container {
    flex: 1;
    min-width: 0; // NOTE: https://css-tricks.com/flexbox-truncated-text/, https://stackoverflow.com/a/39857087

    .message__content {
      display: flex;

      & > div:first-child {
        flex: 2;
        padding-right: 8px;
      }

      & > div {
        min-width: 0;
        flex: 1;
        overflow: hidden;
      }

      .message__title {
        color: #333333;
        font-weight: 500;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:focus {
          color: #1890ff;
        }
      }
    }
  }
}

// NOTE: For reference only, This is when it was using float
//
// .conversation_list_item__content {
//   &::after {
//     display: block;
//     content: "";
//     clear: both;
//   }

//   & > div {
//     min-width: 0;
//     width: 70%;
//     overflow: hidden;
//     float: left;

//     &::after {
//       display: block;
//       content: "";
//       clear: both;
//     }
//   }

//   & > div:first-child {
//     min-width: 0;
//     padding-right: 8px;
//   }

//   & > div:last-child {
//     width: 30%;
//   }
// }
