.aside_filter__sentence_query {
  .ant-collapse {
    margin-top: 16px;
    border: none;


    .ant-collapse-item {
      $border-style: 1px solid #d9d9d9;
      &:first-child {
        .ant-collapse-header {
          border-top: $border-style;
          border-left: $border-style;
          border-right: $border-style;
        }
      }

      .ant-collapse-header {
        padding-top: 1px;
        padding-bottom: 1px;
        border-left: $border-style;
        border-right: $border-style;
      }
    }

    .ant-collapse-content-box {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
