.UtterancesFilter {
  padding-bottom: 16px;

  :global {
    form.ant-form {
      .ant-collapse {
        .ant-collapse-item > .ant-collapse-header {
            display: flex;
            align-items: center;
            max-width: 100%;
            padding: 8px 0;

            span.anticon.ant-collapse-arrow {
              margin-left: 6px;
              margin-right: 6px;
            }
        }
      }
    }
  }
}

.left_sider__collapse_button_container {
  position: absolute;
  right: 0;
}
