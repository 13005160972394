.ViewEmailModal {
  top: 20px;
  bottom: 20px;

  :global {
    .ant-modal-body {
      padding-top: 0;
    }

    .ant-divider-horizontal {
      margin: 12px 0;
    }
  }

  &.ContainizedModalWithinViewport {
    :global {
      .ant-modal-body {
        /*
          55px: header
          12px: divider + padding
          53px: navigation
          40px: tab selection
          -8px: manual offset after manual inspection
        */
        height: calc(100vh - ((55px + 12px + 53px + 40px) - 8px));
        overflow: auto;
        display: flex;
        flex-direction: column;

        padding-bottom: 0;
      }
    }
  }

  .CurrentViewingEmailNavigation {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
  }

  .EmailChecking {
    height: 100%;

    :global {
      .ant-tabs-content-holder,
      .ant-tabs-content.ant-tabs-content-top,
      .ant-spin-nested-loading,
      .ant-spin-container {
        height: 100%;
      }

      .ant-tabs-content-holder {
        position: relative;
      }
    }
  }
}
