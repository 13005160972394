.ConversationSimulation {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .ant-page-header,
  .ant-page-header.ant-page-header-ghost {
    padding: 0;
    .ant-page-header-heading-title .ant-typography {
      margin: 0;
    }
  }

  .simulation_tabs {
    height: 100%;
    .ant-tabs-content.ant-tabs-content-top {
      height: 100%;
    }
  }

  [class*='ChatExchanges_chat_exchange'] {
    padding: 8px 0;
    &:not(:last-child) {
      border-bottom: 2px solid #E7E8EE;
    }

    .converse_options {
      display: flex;
      flex-direction: column;
      flex-basis: 160px;
      padding-right: 8px;

      button[type='button'] {
        flex-basis: auto;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }

  [class*='ChatExchanges_chat_exchange_end_to_end__'] {
    padding-top: 24px;
  }

  // lmao
  div.ant-tabs-nav[role='tablist'] {
    margin-bottom: 12px;
  }

  & .simulator_panels {
    height: 100%;
    display: flex;
    flex-direction: column;

    .simulator_configurations {
      .ant-space-item:nth-child(2) {
        flex: 1;
      }
    }

    .simulator {
      flex: 1;
    }

    .simulation_data {
      display: flex;
      flex-direction: column;
      height: 100%;
      row-gap: 12px;

      textarea.ant-input {
        height: 100%;
      }
    }
  }


  [class*='EndToEndTest_EndToEnd'] {
    & > .ant-row,
    & > .ant-row > .ant-col {
      height: calc(100% - 1px); // for some reason there is border or negative margin somewhere
      overflow: hidden;
    }

    & > .ant-row > .ant-col {
      padding-top: 16px;
      & > *:first-child {
        margin-bottom: 24px;
      }
    }

    & > .ant-row > .ant-col:not(:last-child) {
      border-right: 2px solid #B3B3B3;
      overflow: auto;
    }

    & > .ant-row > .ant-col:first-child,
    & > .ant-row > .ant-col:last-child {
      display: flex;
      flex-direction: column;
    }

    .ChatExchanges {
      overflow: scroll;
    }

    .ExchangeTestCase {
      [class*='ChatExchanges_chat_messages__'] {
        padding: 16px 0;
        border-top: 2px solid #E7E8EE;
        border-bottom: 2px solid #E7E8EE;
      }

      div.ant-tabs-nav[role='tablist'] {
        margin-bottom: 12px;
      }
    }
  }
}
