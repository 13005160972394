@mixin background($settings) {
  -webkit-background: $settings;
  -moz-background: $settings;
  -ms-background: $settings;
  -o-background: $settings;
  background: $settings;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin reset-default-styling {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

// button background and border-color
// if enter only one parameter, it will take the first parameter as a default color

@mixin set-background-and-border-color($background-color, $border-color: $background-color) {
  background-color: $background-color;
  border-color: $border-color;
}

@mixin set-active-color-darken($color, $intensity) {
  $color-darken: darken($color, $intensity);

  &:active {
    @include set-background-and-border-color($color-darken);
  }
}

@mixin transition($property, $delay, $timing-function) {
  -webkit-transition: $property $delay $timing-function;
  -moz-transition: $property $delay $timing-function;
  -ms-transition: $property $delay $timing-function;
  -o-transition: $property $delay $timing-function;
  transition: $property $delay $timing-function;
}

@mixin multiple-transitions($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin transform($value) {
  -webkit-transform: $value;
  -moz-transform: $value;
  -ms-transform: $value;
  -o-transform: $value;
  transform: $value;
}

// http://tobiasahlin.com/blog/how-to-animate-box-shadow/

@mixin box-shadow($horizontal-length, $vertical-length, $blur-radius, $spread-radius, $color, $opacity: 0.5) {
  -webkit-box-shadow: $horizontal-length $vertical-length $blur-radius $spread-radius rgba($color, $opacity);
  -moz-box-shadow: $horizontal-length $vertical-length $blur-radius $spread-radius rgba($color, $opacity);
  -ms-box-shadow: $horizontal-length $vertical-length $blur-radius $spread-radius rgba($color, $opacity);
  -o-box-shadow: $horizontal-length $vertical-length $blur-radius $spread-radius rgba($color, $opacity);
  box-shadow: $horizontal-length $vertical-length $blur-radius $spread-radius rgba($color, $opacity);
}

@mixin box-shadow-inset($horizontal-length, $vertical-length, $blur-radius, $spread-radius, $color, $opacity: 0.5) {
  -webkit-box-shadow: inset $horizontal-length $vertical-length $blur-radius $spread-radius rgba($color, $opacity);
  -moz-box-shadow: inset $horizontal-length $vertical-length $blur-radius $spread-radius rgba($color, $opacity);
  -ms-box-shadow: inset $horizontal-length $vertical-length $blur-radius $spread-radius rgba($color, $opacity);
  -o-box-shadow: inset $horizontal-length $vertical-length $blur-radius $spread-radius rgba($color, $opacity);
  box-shadow: inset $horizontal-length $vertical-length $blur-radius $spread-radius rgba($color, $opacity);
}

@mixin linear-gradient-border($border-width, $border-style, $degree, $color-start, $color-stop, $border-image-slice) {
  border-width: $border-width;
  border-style: $border-style;
  border-image: -webkit-linear-gradient($degree, $color-start, $color-stop) $border-image-slice;
  border-image: -o-linear-gradient($degree, $color-start, $color-stop) $border-image-slice;
  border-image: -moz-linear-gradient($degree, $color-start, $color-stop) $border-image-slice;
  border-image: linear-gradient($degree, $color-start, $color-stop) $border-image-slice;
}

@mixin flex-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-grow($amount) {
  flex-grow: $amount;
}

@mixin flex-shrink($amount) {
  flex-shrink: $amount;
}

@mixin flex-expand() {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

@mixin box-shadow-style($top, $left, $blur, $color) {
  -webkit-box-shadow: $top $left $blur $color;
  -moz-box-shadow: $top $left $blur $color;
  box-shadow: $top $left $blur $color;
}

// Useful for matching something inside grid/row style
@mixin style-class-with-attribute-like-modifer($query, $modifer: "") {
  [class*="#{$query}#{$modifer}"] {
    @content;
  }
}

@mixin box-shadow-style-none {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

// this should be included with the image
// "That is inside a parent" and parent "must have position: relative"
@mixin centerized-image-inside-parent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Object fit allows image inside HTML element to align itself
// to be center, with parent HTML element MUST have finite heights/width
@mixin ie11-object-fit-polyfill {
  // or... > @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) ???
  @supports not (object-fit: cover) {
    @content;
  }
}

@mixin centered-page() {
  @include flex-box();
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}

@mixin linear-gradient($direction, $color1, $color2) {
  background-image: -webkit-linear-gradient($direction, $color1, $color2);
  background-image: -moz-linear-gradient($direction, $color1, $color2);
  background-image: -ms-linear-gradient($direction, $color1, $color2);
  background-image: -o-linear-gradient($direction, $color1, $color2);
  background-image: linear-gradient($direction, $color1, $color2);
}

@mixin mask-image($imgUrl) {
  -webkit-mask-image: url($imgUrl);
  -webkit-mask-size: 100% 100%;
  mask-image: url($imgUrl);
  mask-size: 100% 100%;
}

@mixin iphone-x-only-css {
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

@mixin apply-ios-only {
  @supports (-webkit-overflow-scrolling: touch) {
    @content;
  }
}



@debug ':Mixins loaded';
