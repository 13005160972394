.aside_filter {
  background: #fff;
  padding: 0 16px;
  padding-top: 16px;
  text-align: left;
  overflow-y: auto;

  position: relative;

  .left_sider__divider {
    margin: 8px 0;
  }

  .aside_filter--wrapper {
    margin-bottom: 16px;
  }

  &.collapsed {
    background: transparent;
    overflow: visible;
  }

  .ant-divider {
    & + .ant-form-item,
    & + .ant-row.ant-form-item {
      margin-bottom: 12px;
    }
  }

  .ant-row.ant-form-item {
    margin-bottom: 0px;
  }
}
