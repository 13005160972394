@mixin tertiary-button-white() {
  @include button-shared-property();
  @include button-disabled();
  @include transition(all, 150ms, ease);

  color: #009aef;
  background: #ffffff;
}

@mixin tertiary-button-small-white() {
  @include reset-default-styling();
  @include button-disabled();
  @include transition(all, 150ms, ease);

  cursor: pointer;
  border: none;
  outline: none;
  color: #009aef;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding: 0;
}

@mixin tertiary-button-gray() {
  @include button-shared-property();
  @include button-disabled();
  @include set-hover-background-color(#edf1fc);
  @include set-active-background-color(#f8f9f9);
  @include transition(all, 150ms, ease);

  color: #009aef;
  background: #f7f7f7;
}


@mixin tertiary-button-gray-inactive() {
  @include button-shared-property();
  @include button-disabled();
  @include transition(all, 150ms, ease);

  color: #727c8a;
  background: #f7f7f7;
}

@mixin tertiary-button-red() {
  @include button-shared-property();
  @include button-disabled();
  @include transition(all, 150ms, ease);

  color: $chime-error;
  background: #ffffff;
}

.button-tertiary-gray {
  @include tertiary-button-gray();
}
