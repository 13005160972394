.TestCaseManagement {
  height: 100%;

  .tabTableTitle {
    padding: 8px;
  }

  .testCaseMangementTab {
    height: 100%;

    .tabPanel {
      height: 100%;
      overflow: auto;

      .testModifySpace {
        height: 100%;
        width: 100%;

        .headerColumnFont {
          font-size: 16px;
        }

        :global {
          .ant-space-item {
            height: 100%;
          }
        }
      }
    }
  }

  .testReportTable {

    .testStatus {
      font-weight: bold;
    }

    .testPassed {
      color: limegreen;
    }

    .testFailed {
      color: red
    }

    .line {
      width: 100%;
      height: 5px;

      &.red {
        background: red
      }

      &.green {
        background: limegreen
      }
    }

    :global {
      .ant-collapse-header {
        padding: 0;
      }

      .ant-collapse-content-box {
        padding-bottom: 0;
        padding-left: 24px;
      }
    }
  }

  .height100 {
    height: 100%;
  }

  .scrollAble {
    overflow-y: overlay;
  }
}
