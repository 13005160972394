@use "sass:color";

@mixin generateButtonColorSet($color, $disabled: false) {
  color: $color;
  background: color.change($color, $alpha: 0.1);
  border-color: $color;

  &:hover {
    @if $disabled {
      color: $color;
      border-color: $color;
    } else {
      color: color.change($color, $saturation: 100%);
      border-color: color.change($color, $saturation: 100%);
    }
  }
}

.ApproveAll {
  @include generateButtonColorSet(#6DAE2D);
  &:disabled {
    @include generateButtonColorSet(#D6D6D6, $disabled: true);
  }
}

.RejectAll {
  @include generateButtonColorSet(#F24726);
  &:disabled {
    @include generateButtonColorSet(#D6D6D6, $disabled: true);
  }
}
