.Badge {
  background: #ECF6FF;
  color: #282D3A;
  font-weight: bold;
  text-align: center;

  padding: 4px 6px;

  .Badge_boundary {
    width: 80%;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
