@mixin secondary-button-shade-gray() {
  @include button-shared-property();
  @include button-disabled();
  @include set-hover-background-color(#727c8a);
  @include set-active-background-color(#dce0ea);
  @include transition(all, 150ms, ease);
  color: #ffffff;
  background: #c1cad1;
}

@mixin secondary-button-washed-gray() {
  @include button-shared-property();
  @include button-disabled();
  @include transition(all, 150ms, ease);
  background: #f7f7f7;
  color: #727c8a;
}


.button-secondary-shade-gray {
  @include secondary-button-shade-gray();
}

.button-secondary-washed-gray {
  @include secondary-button-washed-gray();
}
