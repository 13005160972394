.ant-layout-header {
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  line-height: 62px; // NOTE: co-relates to the height of the antd header height value
  padding: 0 16px;

  .Louise--LogoTitle {
    margin-right: 12px;
  }

  img.amexgbt-logo {
    width: 120px;
    height: auto;
    margin-left: -12px; // NOTE: relies on width 120px (1/10 * -1 ?)
  }
}
