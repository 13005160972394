.RadioGroupOptions {
  margin-bottom: 8px;
  margin-left: 0;
  margin-right: 0;

  :global {
    .ant-form-item-label {
      padding-right: 8px;

      & > label {
        height: initial;
        align-items: baseline;
      }

      &.ant-col-xl-24 {
        padding-bottom: 0;

        @media (max-width: 1599px) {
          padding: 0;
        }
      }
    }

    .ant-form-item-control {
      &.ant-col-xl-24 {
        @media (min-width: 1200px) and (max-width:1599px) {
          padding-left: 10px;
        }
      }
    }
  }
}
