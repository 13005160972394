.EmailContentPreview {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  z-index: 0;

  .Header {
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 1;
  }

  .EmailContent {
    white-space: pre-wrap;
  }

  .EmailId {
    font-weight: 700
  }

  .EmailTitle {
    white-space: pre;
  }

  .CopyableIcon {
    color: black
  }
}
