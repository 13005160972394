.EmailAnalysisResponseLog {
  :global {
    .ant-space.ant-space-vertical {
      width: 100%;
    }

    .react-json-view {
      max-height: 25vh;
      overflow: auto;
      border: 1px solid #000000;
      width: 100%;
    }
  }
}
