.TrainingCases {
  .ExistingCases,
  .AddNewCase {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .FormTitle {
    text-transform: capitalize;
  }
}
