.Main {
  height: 100%;
}

.Main__content {
  background: #fff;
  position: relative;
  padding: 0;
  text-align: left;
  height: 100%;
  z-index: 0;
}
