.delete_button__choice_category_form {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
}

.delete_button__choice_category_form:hover {
    color: #777;
}

.delete_button__choice_category_form[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}
